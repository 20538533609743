.PS {
    display: grid; 
    gap: 25px;

    .data-row.group-list {
        grid-template-columns: unset;
    }

    .data-grouping-list {
        flex-wrap: wrap;
    }

    th {
        th {
            padding: 1em;
        }
    }
}

.upload-progress-bars {
    display: grid;
    grid-template-columns: 1fr min-content 1fr;
    align-items: center;
    grid-gap: 1em;

    .progress-bar {
        display: flex;
        height: 1rem;
        overflow: hidden;
        border-radius: .25rem;
        background-color: #e9ecef;
        background: linear-gradient(to right, #007bff var(--progress), #e9ecef 0%);
    }

    .progress-text {
        margin: 0;
        font-size: small;
        background: linear-gradient(to right, white var(--progress), black 0%);
        color: transparent;
        background-clip: text;
    }

    .progress-upload-cancel {
        color: black;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 1em;
    }
}

.ReactModal__Overlay .ReactModal__Content .dropzone.mds-upload-button {
    height: unset;
    padding: 0em 1em;
    min-width: 10em;
    margin: auto;
    width: fit-content; 
    cursor: pointer;
    border-radius: 5px;
    color: white;
}

@media print {
    .PS {
        grid-template-columns: auto;
    }
    .PS .recharts-wrapper {
        height: 700px !important;
    }
    .PS .using-graph {
        min-height: 700px !important;
        max-height: 900px !important;
    }
}