.AddressAssessment {
    display: grid; 
    grid-template-columns: 4fr 1fr; 
    gap: 25px;

    .data-row.group-list {
        grid-template-columns: unset;
    }

    .data-grouping-list {
        flex-wrap: wrap;
    }

    th {
        th {
            padding: 1em;
        }
    }
}

.ReactModal__Overlay .ReactModal__Content .dropzone.mds-upload-button {
    height: unset;
    padding: 0em 1em;
    min-width: 10em;
    margin: auto;
    width: fit-content; 
    cursor: pointer;
    border-radius: 5px;
    color: white;
}

@media print {
    .PS {
        grid-template-columns: auto;
    }
    .PS .recharts-wrapper {
        height: 700px !important;
    }
    .PS .using-graph {
        min-height: 700px !important;
        max-height: 900px !important;
    }
}